<template>
  <v-container fluid>
    <v-row>
      <v-col md="6" cols="12">
        <div class="conOfHeader pl-5">
          <div class="headerOfPage">All Projects</div>
        </div>
      </v-col>

      <v-col md="6" cols="12">
        <div class="conOfHeader text-right pr-5">
          <div class="btnAdd">
            <v-btn class="btnAddPrimary" @click="openDialogProject()">
              <v-icon>mdi-plus</v-icon>
              Create New Project
            </v-btn>
          </div>
        </div>
      </v-col>
    </v-row>
    <div class="d-flex justify-space-between mb-5">
      <form class="conFormSubmit conSearchForm mt-5">
        <v-row>
          <v-col md="3" sm="6" cols="12">
            <label class="eachLabel">Company</label>
            <v-autocomplete
              outlined
              v-model="search.company_id"
              item-text="name"
              item-value="id"
              :items="listCompanies"
              placeholder="Company"
              hide-details="auto"
              append-icon="mdi-chevron-down"
              :disabled="isLoadingCompanies"
              :loading="isLoadingCompanies"
              :menu-props="{ bottom: true, offsetY: true }"
              attach
            >
            </v-autocomplete>
          </v-col>
          <v-col md="3" sm="6" cols="12">
            <label class="eachLabel">Country</label>
            <v-autocomplete
              outlined
              v-model="search.country_id"
              item-text="name"
              item-value="id"
              :items="countryList"
              placeholder="Country"
              hide-details="auto"
              append-icon="mdi-chevron-down"
              :disabled="isLoadingUsersList"
              :loading="isLoadingUsersList"
              :menu-props="{ bottom: true, offsetY: true }"
              attach
            >
            </v-autocomplete>
          </v-col>
          <v-col md="3" sm="6" cols="12">
            <label class="eachLabel">Status</label>
            <v-select
              outlined
              v-model="search.status"
              :items="statusList"
              item-text="name"
              item-value="id"
              placeholder="Status"
              hide-details="true"
              :menu-props="{ bottom: true, offsetY: true }"
              attach
              append-icon="mdi-chevron-down"
            ></v-select>
          </v-col>

          <v-col md="3" sm="6" cols="12">
            <div class="confBtns d-flex">
              <v-btn
                class="srearchBnt btnAddPrimaryOutline mr-4"
                @click="onSearch"
                :disabled="isLoadingSearch"
                :loading="isLoadingSearch"
              >
                SEARCH
              </v-btn>
              <v-btn class="clearBtn btnCancelOutline" @click="onClear">
                CLEAR
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </form>

      <form
        v-on:submit.prevent="onSearchTxt()"
        class="conFormSubmit conSearchForm mt-5"
      >
        <v-row>
          <v-col cols="12">
            <label class="eachLabel">Search</label>

            <v-text-field
              outlined
              v-model="searchTxt"
              type="text"
              class="form-control"
              id="inputSearch"
              placeholder="Search"
              aria-label="Search"
              aria-describedby="search-addon"
              append-icon="mdi-magnify"
            >
            </v-text-field>
          </v-col>
        </v-row>
      </form>
    </div>
    <v-row v-if="listData && listData.length > 0">
      <v-col cols="12">
        <section class="conOfProjects">
          <div class="conOfTable">
            <v-data-table
              :headers="headers"
              :items="listData"
              :items-per-page="7"
              hide-default-footer
            >
              <template v-slot:[`item.actions`]="{ item }">
                <v-menu bottom :left="true" :offset-y="true">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn dark icon v-bind="attrs" v-on="on">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <template
                      v-if="
                        item.status == 1 &&
                        (user.id == item.assign_to || user.id == item.user.id)
                      "
                    >
                      <v-list-item @click="changeStatusItem(item, 'done')">
                        <v-list-item-title class="statusTxt">
                          Done
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="changeStatusItem(item, 'cancel')">
                        <v-list-item-title class="statusTxt">
                          Canceled
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                    <v-list-item @click="poModification(item)">
                      <v-list-item-title class="statusTxt">
                        {{ item.PO ? "Edit PO" : "Add PO" }}
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="editItem(item)">
                      <v-list-item-title class="statusTxt">
                        Edit
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      :to="{
                        name: 'PurchasingAllRequests',
                        params: { id: item.id },
                      }"
                    >
                      <v-list-item-title class="statusTxt">
                        View Requests
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
              <template v-slot:[`item.project_name`]="{ item }">
                <v-badge
                  class="mr-3"
                  bordered
                  dot
                  color="red"
                  v-if="item.have_action"
                >
                </v-badge>
                <router-link
                  :to="{
                    name: 'PurchasingAllRequests',
                    params: { id: item.id },
                  }"
                >
                  {{ item.project_name }}
                </router-link>
              </template>
            </v-data-table>
          </div>
        </section>
        <div
          class="conOfPagination pr-5 pt-5"
          v-if="pagination.total > pagination.per_page"
        >
          <v-pagination
            :total-visible="7"
            v-model="pagination.current_page"
            :length="pagination.last_page"
            @input="goToPage(pagination.current_page)"
          ></v-pagination>
        </div>
      </v-col>
    </v-row>

    <div class="loaderContainer" v-else-if="isLoading">
      <v-progress-circular
        :size="120"
        :width="4"
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
    <v-row v-else>
      <v-col cols="12" class="mt-7">
        <EmptyState />
      </v-col>
    </v-row>
    <v-snackbar
      v-model="successSnackbar"
      color="success"
      shaped
      absolute
      top
      right
      :timeout="timeout"
    >
      {{ successMessage }}
    </v-snackbar>
    <v-snackbar
      v-model="errorSnackbar"
      color="red"
      shaped
      top
      right
      :timeout="timeout"
    >
      {{ errorMessage }}
    </v-snackbar>
    <v-dialog scrollable v-model="dialogProject" max-width="800" persistent>
      <v-card class="conOfPopup">
        <v-card-title>
          <span class="text-h5 callbacks modalTitle">{{ formTitle }}</span>
        </v-card-title>

        <v-card-text class="conFormSubmit scrollbar">
          <v-container>
            <v-row>
              <v-col md="12" cols="12">
                <v-radio-group
                  v-model="formItem.country_id"
                  required
                  :error-messages="country_idErrors"
                  @input="$v.formItem.country_id.$touch()"
                  @blur="$v.formItem.country_id.$touch()"
                  row
                >
                  <v-radio value="1"></v-radio>
                  <label
                    ><img src="@/assets/img/logo_cat.png" class="mr-4"
                  /></label>
                  <v-radio required value="2"></v-radio>
                  <label><img src="@/assets/img/logo_237.png" /></label>
                </v-radio-group>
              </v-col>

              <v-col md="6" cols="12">
                <label class="eachLabel">Company</label>

                <v-autocomplete
                  @keyup="getProducts"
                  outlined
                  v-model="formItem.company_id"
                  item-text="name"
                  item-value="id"
                  :items="listCompanies"
                  placeholder="Company"
                  hide-details="auto"
                  :menu-props="{ bottom: true, offsetY: true }"
                  append-icon="mdi-chevron-down"
                  :loading="isLoadingCompanies"
                  required
                  :error-messages="company_idErrors"
                  @input="$v.formItem.company_id.$touch()"
                  @blur="$v.formItem.company_id.$touch()"
                  return-object
                >
                </v-autocomplete>
              </v-col>

              <v-col md="6" cols="12">
                <label class="eachLabel">Project Name</label>
                <v-text-field
                  class="eachInput"
                  placeholder="Project Name"
                  outlined
                  solo
                  hide-details="auto"
                  v-model="formItem.project_name"
                  required
                  :error-messages="project_nameErrors"
                  @input="$v.formItem.project_name.$touch()"
                  @blur="$v.formItem.project_name.$touch()"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <label class="eachLabel">Client Name</label>
                <v-text-field
                  class="eachInput"
                  placeholder="Client Name"
                  outlined
                  solo
                  hide-details="auto"
                  v-model="formItem.client_name"
                  required
                  :error-messages="client_nameErrors"
                  @input="$v.formItem.client_name.$touch()"
                  @blur="$v.formItem.client_name.$touch()"
                ></v-text-field>
              </v-col>
              <v-col md="6" cols="12">
                <label class="eachLabel">Country</label>
                <v-text-field
                  class="eachInput"
                  placeholder="Country"
                  outlined
                  solo
                  hide-details="auto"
                  v-model="formItem.project_country"
                  required
                  :error-messages="project_countryErrors"
                  @input="$v.formItem.project_country.$touch()"
                  @blur="$v.formItem.project_country.$touch()"
                ></v-text-field>
              </v-col>
              <v-col md="6" cols="12">
                <label class="eachLabel">Project timeline</label>
                <div class="conDatePicker">
                  <v-menu
                    v-model="menu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        class="eachInput"
                        placeholder="Project timeline"
                        solo
                        v-model="formItem.project_timeline"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        required
                        :error-messages="project_timelineErrors"
                        @input="$v.formItem.project_timeline.$touch()"
                        @blur="$v.formItem.project_timeline.$touch()"
                        hide-details="auto"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="formItem.project_timeline"
                      @input="menu = false"
                    ></v-date-picker>
                  </v-menu>
                </div>
              </v-col>
              <v-col
                cols="12"
                v-if="user.team_leader == true || user.role_id != 1"
                :class="this.isManaged ? 'd-none' : ''"
              >
                <div v-if="user.parent_id != 11">
                  <label class="eachLabel">Sales Contact</label>
                  <v-autocomplete
                    outlined
                    v-model="formItem.assign_to"
                    item-text="name"
                    item-value="id"
                    :items="listTeamLeaders"
                    placeholder="Sales Contact"
                    hide-details="auto"
                    :menu-props="{ bottom: true, offsetY: true }"
                    append-icon="mdi-chevron-down"
                    :disabled="isLoadingUsersList || isManaged"
                    :loading="isLoadingUsersList"
                  >
                    <template slot="selection" slot-scope="data">
                      {{ data.item.name }}
                      <span class="leaderSpan">
                        {{ data.item.team_leader ? "Leader" : "" }}
                      </span>
                    </template>
                    <template slot="item" slot-scope="data">
                      {{ data.item.name }}
                      <span class="leaderSpan">
                        {{ data.item.team_leader ? "Leader" : "" }}
                      </span>
                    </template>
                  </v-autocomplete>
                </div>
              </v-col>
              <v-col
                cols="12"
                v-if="user.team_leader == true || user.role_id != 1"
              >
                <v-checkbox
                  v-if="user.parent_id != 11"
                  class="checkBoxStyle mt-0 pt-0"
                  v-model="isManaged"
                  label="Manage this project by myself"
                  rounded
                  @change="checkIfManageBySelf()"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <template v-if="errorMessage">
          <div v-for="(error, i) in errorMessage" :key="i" color="red">
            <span v-for="item in error" :key="item">
              <v-alert type="error">
                {{ item }}
              </v-alert>
            </span>
          </div>
        </template>
        <v-card-actions class="pb-10">
          <v-spacer></v-spacer>
          <v-btn class="btnCancel" tile @click="closeProjectModal">
            Cancel
          </v-btn>
          <v-btn
            class="btnPrimaryOrg"
            @click="saveItem"
            :disabled="isLoadingSave"
            :loading="isLoadingSave"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogProjectPo" max-width="500" persistent>
      <v-card class="conOfPopup">
        <v-card-title>
          <span class="text-h5 callbacks modalTitle">{{ formTitlePO }}</span>
        </v-card-title>

        <v-card-text class="conFormSubmit">
          <v-container>
            <v-row>
              <v-col cols="12">
                <label class="eachLabel">PO Number</label>
                <v-text-field
                  class="eachInput"
                  placeholder="PO Number"
                  outlined
                  solo
                  hide-details="auto"
                  v-model="formItemPO.number"
                  required
                  :error-messages="numberErrors"
                  @input="$v.formItemPO.number.$touch()"
                  @blur="$v.formItemPO.number.$touch()"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions class="pb-10">
          <v-spacer></v-spacer>
          <v-btn class="btnCancel" tile @click="closeDialogProjectPo">
            Cancel
          </v-btn>
          <v-btn
            class="btnPrimaryOrg"
            @click="savePOItem"
            :disabled="isLoadingSavePO"
            :loading="isLoadingSavePO"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <ConfirmDialog
      v-if="dialogConform"
      v-model="dialogConform"
      @confirmAction="cofirmAction()"
    />
  </v-container>
</template>

<script>
import { ApiService } from "@/modules/shared/services/api";
let apiServices = new ApiService();
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import EmptyState from "@/modules/shared/components/emptystate";
import ConfirmDialog from "@/modules/shared/components/confirm-popup";
import { mapGetters } from "vuex";
import store from "@/store";
export default {
  mixins: [validationMixin],
  validations() {
    const validations = {
      formItem: {
        company_id: { required },
        country_id: { required },
        project_name: { required },
        client_name: { required },
        project_country: { required },
        project_timeline: { required },
      },
      formItemPO: {
        number: { required },
      },
    };
    return validations;
  },
  data: () => ({
    successSnackbar: false,
    errorSnackbar: false,
    timeout: 3000,
    typeError: false,
    column: null,
    row: null,
    menu: false,
    dialogProjectParam: 0,
    dialogProject: false,
    dialogConform: false,
    isLoadingSave: false,
    isLoadingUsersList: false,
    isLoadingCompanies: false,

    isLoading: false,
    editedIndex: -1,
    listTeamLeaders: [],
    listCompanies: [],
    pagination: {
      current_page: 1,
    },

    pagination_companies: {
      current_page: 1,
    },
    isManaged: true,
    formItem: { assign_to: "", company_id: "", country_id: "" },
    defaultItem: { assign_to: "" },
    headers: [
      {
        text: "COMPANY",
        align: "start",
        value: "client_company",
        sortable: false,
      },
      { text: "PROJECT NAME", value: "project_name" },
      { text: "CLIENT NAME", value: "client_name" },
      { text: "COUNTRY PROJECT", value: "project_country" },
      { text: "COUNTRY", value: "country_ISO" },
      { text: "TIMELINE", value: "project_timeline" },
      { text: "STATUS", value: "status_message" },
      { text: "CREATED BY", value: "user.name" },
      { text: "", value: "actions", sortable: false },
    ],
    listData: [],
    actionNeeded: null,
    statusList: [
      {
        id: "1",
        name: "New Project",
      },

      {
        id: "2",
        name: "Project Done",
      },
      {
        id: "0",
        name: "Project Canceled",
      },
    ],
    countryList: [
      {
        id: "1",
        name: "Egypt",
      },
      {
        id: "2",
        name: "KSA",
      },
    ],
    errorMessage: null,
    dialogProjectPo: false,
    isLoadingSavePO: false,
    isLoadingSearch: false,
    formItemPO: {},
    defaultItemPO: {},
    editedIndexPO: -1,
    updatedFilter: "",
    updatedFilterTxt: "",
    updatedFilterCompanies: "",
    searchTxt: "",
    searchCompanies: "",

    search: {
      country_id: "",
      status: "",
      company_id: "",
    },
  }),
  components: { EmptyState, ConfirmDialog },
  computed: {
    ...mapGetters(["user", "apiMessage"]),
    formTitle() {
      return this.editedIndex === -1 ? "Add New Project" : "Edit Project";
    },
    formTitlePO() {
      return this.editedIndexPO === -1 ? "Add PO" : "Edit PO";
    },
    company_idErrors() {
      const errors = [];
      if (!this.$v.formItem.company_id.$dirty) return errors;
      !this.$v.formItem.company_id.required &&
        errors.push("Company is Required.");
      return errors;
    },
    country_idErrors() {
      const errors = [];
      if (!this.$v.formItem.country_id.$dirty) return errors;
      !this.$v.formItem.country_id.required &&
        errors.push("country is Required.");
      return errors;
    },
    project_nameErrors() {
      const errors = [];
      if (!this.$v.formItem.project_name.$dirty) return errors;
      !this.$v.formItem.project_name.required &&
        errors.push("project Name Is Required.");
      return errors;
    },

    client_nameErrors() {
      const errors = [];
      if (!this.$v.formItem.client_name.$dirty) return errors;
      !this.$v.formItem.client_name.required &&
        errors.push("Client Name Is Required.");
      return errors;
    },
    project_countryErrors() {
      const errors = [];
      if (!this.$v.formItem.project_country.$dirty) return errors;
      !this.$v.formItem.project_country.required &&
        errors.push("country Is Required.");
      return errors;
    },

    project_timelineErrors() {
      const errors = [];
      if (!this.$v.formItem.project_timeline.$dirty) return errors;
      !this.$v.formItem.project_timeline.required &&
        errors.push("project Timelines Is Required.");
      return errors;
    },
    numberErrors() {
      const errors = [];
      if (!this.$v.formItemPO.number.$dirty) return errors;
      !this.$v.formItemPO.number.required &&
        errors.push("PO Number Is Required.");
      return errors;
    },
  },
  methods: {
    poModification(item) {
      if (item.PO) {
        this.editedIndexPO = item.PO;
        this.formItemPO.number = item.PO;
      } else this.editedIndexPO = -1;
      this.formItemPO.project_id = String(item.id);
      this.dialogProjectPo = true;
    },
    savePOItem() {
      this.$v.$touch();
      if (this.$v.formItemPO.$error) return;
      this.isLoadingSavePO = true;

      apiServices.post("pos", this.formItemPO).then((res) => {
        if (res) {
          this.pagination.current_page = 1;
          this.getListData(this.pagination.current_page);
          this.isLoadingSavePO = false;
          this.closeDialogProjectPo();
          this.successMessage = res.message || "Successful";
          this.successSnackbar = true;
        } else {
          this.isLoadingSavePO = false;
          let errors = Object.values(this.apiMessage)[0];
          errors.forEach((err) => {
            this.errorMessage = err;
          });
          this.errorSnackbar = true;
        }
      });
    },
    onSearch() {
      this.pagination.current_page = 1;
      this.pathByOaramSearch();
    },
    onSearchTxt() {
      this.pagination.current_page = 1;
      this.pathByOaramSearchTxt();
    },
    onSearchCompanies() {
      this.pagination_companies.current_page = 1;
      if (event.key == "Enter") {
        this.getCompaniesList();
      }
    },
    onClear() {
      this.search.country_id = "";
      this.search.company_id = "";
      this.search.status = "";
      this.pathByOaramSearch();
    },
    pathByOaramSearch() {
      const query = Object.entries(this.search).reduce((acc, [key, val]) => {
        if (!val) return acc;
        return { ...acc, [key]: val };
      }, {});
      if (JSON.stringify(this.updatedFilter) != JSON.stringify(query)) {
        this.$router.replace({
          name: "allProductsReport",
          query: query,
        });
        this.updatedFilter = query;
      }
    },
    pathByOaramSearchTxt() {
      const query = Object.entries(this.searchTxt).reduce((acc, [key, val]) => {
        if (!val) return acc;
        return { ...acc, [key]: val };
      }, {});
      if (JSON.stringify(this.updatedFilterTxt) != JSON.stringify(query)) {
        this.$router.replace({
          name: "allProductsReport",
          query: query,
        });
        this.updatedFilterTxt = query;
      }
    },
    pathByOaramSearchCompanies() {
      const query = Object.entries(this.formItem.company_id).reduce(
        (acc, [key, val]) => {
          if (!val) return acc;
          return { ...acc, [key]: val };
        },
        {}
      );
      if (
        JSON.stringify(this.updatedFilterCompanies) != JSON.stringify(query)
      ) {
        this.$router.replace({
          name: "allProductsReport",
          query: query,
        });
        this.updatedFilterCompanies = query;
      }
    },
    pathByParamDialog() {
      this.$router.replace({
        name: "allProductsReport",
        query: { dialogProjectParam: this.dialogProjectParam },
      });
    },
    getProducts(event) {
      this.searchCompanies = event.target.value;
      console.log(this.searchCompanies);
      setTimeout(() => {
        // this.formItem.company_id = event.target.value;
        // console.log("event", event);
        // this.searchCompanies = event.target.value
        this.getCompaniesList();
      }, 1000);
    },
    openDialogProject() {
      // console.log("tttttttttttt");
      // this.dialogProject = true;
      this.dialogProjectParam = 1;
      this.pathByParamDialog();
    },
    initializePage() {
      this.$route.query.dialogProjectParam == 1
        ? (this.dialogProject = true)
        : (this.dialogProject = false);
    },
    editItem(item) {
      this.editedIndex = item.id;
      if (!item.assign_to) this.isManaged = true;
      let editFormItem = {
        country_id: item.country_id.toString(),
        company_id: item.company,
        project_name: item.project_name,
        client_name: item.client_name,
        project_country: item.project_country,
        project_timeline: item.project_timeline,
        assign_to: item.assign_to,
      };
      console.log("dd", item);
      this.formItem = editFormItem;
      this.dialogProjectParam = 1;
      this.pathByParamDialog();
      // this.dialogProject = true;
    },
    changeStatusItem(item, actionNeed) {
      this.editedIndex = item.id;
      this.actionNeeded = actionNeed;
      this.dialogConform = true;
    },
    cofirmAction() {
      apiServices
        .updateWithURL(`projects/${this.editedIndex}/${this.actionNeeded}`)
        .then((res) => {
          if (res) {
            this.pagination.current_page = 1;
            this.getListData(this.pagination.current_page);
            this.closeConfirmDialog();
          } else {
            this.closeConfirmDialog();
          }
        });
    },
    closeConfirmDialog() {
      this.dialogConform = false;
      this.editedIndex = -1;
      setTimeout(() => {
        this.$nextTick(() => {
          this.$v.$reset();
          this.formItem = Object.assign({}, this.defaultItem);
        });
      }, 100);
    },
    itemEdited() {
      this.$v.$touch();
      if (this.$v.formItem.$error) return;
      this.isLoadingSave = true;
      this.formItem.company_id = this.formItem.company_id.id;

      apiServices
        .update(this.editedIndex, "projects", this.formItem)
        .then((res) => {
          if (res) {
            this.pagination.current_page = 1;
            this.getListData(this.pagination.current_page);
            this.isLoadingSave = false;
            this.closeProjectModal();
            this.successMessage = res.message || "Successful";
            this.successSnackbar = true;
          } else {
            this.isLoadingSave = false;
            let errors = Object.values(this.apiMessage)[0];
            errors.forEach((err) => {
              this.errorMessage = err;
            });
            this.errorSnackbar = true;
          }
        });
    },
    itemAddNew() {
      this.$v.$touch();
      if (this.$v.formItem.$error) return;
      this.isLoadingSave = true;
      this.formItem.company_id = this.formItem.company_id.id;
      console.log("formItem.company_id", this.formItem.company_id);
      apiServices.post("projects", this.formItem).then((res) => {
        if (res) {
          // console.log("add", res);
          this.pagination.current_page = 1;
          this.getListData(this.pagination.current_page);
          this.isLoadingSave = false;
          this.closeProjectModal();
          this.successMessage = res.message || "Successful";
          this.successSnackbar = true;
        } else {
          this.isLoadingSave = false;
          let errors = Object.values(this.apiMessage)[0];
          errors.forEach((err) => {
            this.errorMessage = err;
          });
          this.errorSnackbar = true;
        }
      });
    },
    saveItem() {
      if (this.editedIndex > -1) {
        this.itemEdited();
      } else {
        this.itemAddNew();
      }
    },
    closeDialogProjectPo() {
      this.dialogProjectPo = false;
      this.editedIndexPO = -1;
      this.$nextTick(() => {
        this.$v.$reset();
        this.formItemPO = Object.assign({}, this.defaultItemPO);
      });
    },
    closeProjectModal() {
      this.dialogProject = false;
      this.dialogProjectParam = 0;
      this.pathByParamDialog();
      this.isManaged = true;
      this.$nextTick(() => {
        this.$v.$reset();
        this.formItem = Object.assign({}, this.defaultItem);
      });
    },
    goToPage(page) {
      this.getListData(page);
      window.scrollTo(0, 0);
    },
    getListData(page) {
      this.listData = [];
      this.isLoading = true;
      this.isLoadingSearch = true;
      apiServices
        .get(
          `projects?per_page=7&page=${page}&company_id=${this.search.company_id}&country_id=${this.search.country_id}&status=${this.search.status}&search=${this.searchTxt}`
        )
        .then((res) => {
          if (res) {
            store.commit("setCreatedByUser", this.listData.user);

            this.isLoadingSearch = false;
            this.listData = res.data;
            this.pagination = res.meta;
            this.isLoading = false;
          } else this.isLoading = false;
        });
    },
    getUsersList() {
      this.listTeamLeaders = [];
      this.isLoadingUsersList = true;
      apiServices.post("auth/myteam").then((res) => {
        if (res) {
          if (res.data.length > 0) {
            this.listTeamLeaders = res.data;
          }
          this.isLoadingUsersList = false;
        } else this.isLoadingUsersList = false;
      });
    },
    getCompaniesList() {
      console.log("entered");
      this.isLoadingCompanies = true;
      apiServices
        .get(`companies?search=${this.searchCompanies}`)
        .then((res) => {
          if (res) {
            if (res.data.length > 0) {
              this.listCompanies = res.data;

              this.pagination_companies = res.meta;
            }
            this.isLoadingCompanies = false;
          } else this.isLoadingCompanies = false;
        });
    },
    checkIfManageBySelf() {
      if (this.isManaged) {
        this.formItem.assign_to = "";
      }
    },
  },
  created() {
    // this.pathByParamDialog();

    this.getListData(this.pagination.current_page);
    this.getUsersList();
    this.getCompaniesList();
  },
  watch: {
    dialogProjectPo(val) {
      val || this.closeDialogProjectPo();
    },
    dialogConform(val) {
      val || this.closeConfirmDialog();
    },
    "$route.params.query": {
      handler: function () {
        this.initializePage();
        this.getListData(this.pagination.current_page);
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./_projects.scss";
</style>
